import cover1 from "../../../../../assets/images/project/rumah/HNS_PIK2_HOUSE/00_pik2_cover.jpg";
import cover2 from "../../../../../assets/images/project/rumah/HNS_PIK2_HOUSE/01_pik2.jpg";
import cover3 from "../../../../../assets/images/project/rumah/HNS_PIK2_HOUSE/02_pik2.jpg";
import cover4 from "../../../../../assets/images/project/rumah/HNS_PIK2_HOUSE/03_pik2.jpg";

export default [
  {
    title: "HNS House",
    description: "Rumah modern kontemporer - PIK2",
    urls: cover1,
  },
  {
    title: "",
    description: "",
    urls: cover2,
  },
  {
    title: "",
    description: "",
    urls: cover3,
  },
  {
    title: "",
    description: "",
    urls: cover4,
  },
];
