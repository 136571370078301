import React, { useState, useEffect, useRef, Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import './App.css';

/**
const Home = lazy(() => import(""));
*/

import Home from "./components/pages/Home";
import SmoothScrollToTop from './components/interface/smooth_scroll_to_top/SmoothScrollToTop';

import ProjectInterior from "./components/pages/project_gallery/ProjectInterior";
import ProjectKomersial from "./components/pages/project_gallery/ProjectKomersial";
import ProjectMasjid from "./components/pages/project_gallery/ProjectMasjid";
import ProjectMasterplan from "./components/pages/project_gallery/ProjectMasterplan";
import ProjectRumah from "./components/pages/project_gallery/ProjectRumah";
import ErrorPage from './components/pages/ErrorPage';

import DefaultScrollToTop from './components/interface/default_scroll_to_top/DefaultScrollToTop';
import MapsLocation from "./components/interface/maps/MapsLocation";
import ContactUs from './components/pages/ContactUs';
import FreeDesign from './components/pages/FreeDesign';
import AppBlog from './components/interface/blog/AppBlog';
import About from './components/pages/About';
import Service from './components/pages/Service';
import ExampleBlog1 from './components/interface/blog/pages/blog_content/ExampleBlog1';
import AppRekanata from './components/pages/components/rekanata/AppRekanata';
import OurServiceViewAll from './components/pages/components/rekanata/pages/detail_about_page/OurServiceViewAll';
import DesignAndBuild from './components/pages/components/rekanata/pages/detail_about_page/page_section/DesignAndBuild';
import InteriorDesign from './components/pages/components/rekanata/pages/detail_about_page/page_section/InteriorDesign';
import Masjid from './components/pages/components/rekanata/pages/detail_about_page/page_section/Masjid';
import Masterplan from './components/pages/components/rekanata/pages/detail_about_page/page_section/Masterplan';
import Renovasi from './components/pages/components/rekanata/pages/detail_about_page/page_section/Renovasi';
import AppDetailPortofolioAwiligarHouse from './components/pages/components/portofolio/detail_portofolio/detail_portofolio_awiligar_house/AppDetailPortofolioAwiligarHouse';
import AppDetailPortofolio01 from './components/pages/components/portofolio/detail_portofolio/detail_portofolio_01/AppDetailPortofolio01';
import AppDetailPortofolioFawnHouse from './components/pages/components/portofolio/detail_portofolio/detail_portofolio_fawn-house/AppDetailPortofolioFawnHouse';
import AppDetailPortofolioVillaHouse from './components/pages/components/portofolio/detail_portofolio/detail_portofolio_villa_house/AppDetailPortofolioVillaHouse';
import AppDetailPortofolioYtmHouse from './components/pages/components/portofolio/detail_portofolio/detail_portofolio_ytm_house/AppDetailPortofolioYtmHouse';
import AppDetailPortofolioYdiHouse from './components/pages/components/portofolio/detail_portofolio/detail_portofolio_ydi_house/AppDetailPortofolioYdiHouse';
import AppDetailPortofolioDodHouse from './components/pages/components/portofolio/detail_portofolio/detail_portofolio_dod_house/AppDetailPortofolioDodHouse';
import AppDetailPortofolioMafSd from './components/pages/components/portofolio/detail_portofolio/detail_portofolio_maf-sd/AppDetailPortofolioMafSd';
import AppDetailPortofolioCiforHouse from './components/pages/components/portofolio/detail_portofolio/detail_portofolio_cifor_house/AppPortofolioCiforHouse';
import AppDetailPortofolioDarussalamMosque from './components/pages/components/portofolio/detail_portofolio/detail_portofolio_darussalam_mosque/AppDetailPortofolioDarussalamMosque';
import Portofolio from './components/pages/components/portofolio/Portofolio';

/** MASJID GALLERY */
import MasjidAceh from './components/pages/components/portofolio/design/design_masjid/portofolio_masjid/masjid_aceh/MasjidAceh';
import MasjidPadang from './components/pages/components/portofolio/design/design_masjid/portofolio_masjid/masjid_padang/MasjidPadang';
import MasjidAlWushul from './components/pages/components/portofolio/design/design_masjid/portofolio_masjid/masjid_al_wushul/MasjidAlWushul';
import MasjidCempaka from './components/pages/components/portofolio/design/design_masjid/portofolio_masjid/masjid_al_yusna_cempaka_adipura/MasjidCempaka';
import MasjidAlAzhar from './components/pages/components/portofolio/design/design_masjid/portofolio_masjid/masjid_al_azhar/MasjidAlAzhar';
import MasjidAsySyifa from './components/pages/components/portofolio/design/design_masjid/portofolio_masjid/masjid_asy_syifa/MasjidAsySyifa';
import MasjidBaitulMuslimin from './components/pages/components/portofolio/design/design_masjid/portofolio_masjid/masjid_baitul_muslimin/MasjidBaitulMuslimin';
import MasjidBumiKiaraPayung from './components/pages/components/portofolio/design/design_masjid/portofolio_masjid/masjid_bumi_kiara_payung/MasjidBumiKiaraPayung';
import MasjidCordova1 from './components/pages/components/portofolio/design/design_masjid/portofolio_masjid/masjid_cordova_1/MasjidCordova1';
import MasjidCordova2 from './components/pages/components/portofolio/design/design_masjid/portofolio_masjid/masjid_cordova_2/MasjidCordova2';
import MasjidDarulMuttaqin from './components/pages/components/portofolio/design/design_masjid/portofolio_masjid/masjid_darul_muttaqin/MasjidDarulMuttaqin';
import MasjidKafPwk from './components/pages/components/portofolio/design/design_masjid/portofolio_masjid/masjid_kaf_pwk/MasjidKafPwk';
import MasjidKalang from './components/pages/components/portofolio/design/design_masjid/portofolio_masjid/masjid_kalang/MasjidKalang';
import MasjidKalangsari from './components/pages/components/portofolio/design/design_masjid/portofolio_masjid/masjid_kalangsari/MasjidKalangsari';
import MasjidKertayasa from './components/pages/components/portofolio/design/design_masjid/portofolio_masjid/masjid_kertayasa/MasjidKertayasa';
import MasjidLela from './components/pages/components/portofolio/design/design_masjid/portofolio_masjid/masjid_lela/MasjidLela';
import MasjidMiftahurrahmah from './components/pages/components/portofolio/design/design_masjid/portofolio_masjid/masjid_miftahurrahmah/MasjidMiftahurrahmah';
import MasjidMuhajirin from './components/pages/components/portofolio/design/design_masjid/portofolio_masjid/masjid_muhajirin/MasjidMuhajirin';
import MasjidNurulislam from './components/pages/components/portofolio/design/design_masjid/portofolio_masjid/masjid_nurulislam/MasjidNurulislam';
import MasjidPacet from './components/pages/components/portofolio/design/design_masjid/portofolio_masjid/masjid_pacet/MasjidPacet';
import MasjidParigi from './components/pages/components/portofolio/design/design_masjid/portofolio_masjid/masjid_parigi/MasjidParigi';
import MasjidPondokPinang from './components/pages/components/portofolio/design/design_masjid/portofolio_masjid/masjid_pondok_pinang/MasjidPondokPinang';
import MasjidRayaBumiOrange from './components/pages/components/portofolio/design/design_masjid/portofolio_masjid/masjid_raya_bumi_orange/MasjidRayaBumiOrange';
import MasjidSmpMutiara from './components/pages/components/portofolio/design/design_masjid/portofolio_masjid/masjid_smp_mutiara/MasjidSmpMutiara';
import MasjidSragen from './components/pages/components/portofolio/design/design_masjid/portofolio_masjid/masjid_sragen/MasjidSragen';
import MasjidSukamanah from './components/pages/components/portofolio/design/design_masjid/portofolio_masjid/masjid_sukamanah/MasjidSukamanah';
import MasjidTumbuh from './components/pages/components/portofolio/design/design_masjid/portofolio_masjid/masjid_tumbuh/MasjidTumbuh';
import MasjidUjungGenteng from './components/pages/components/portofolio/design/design_masjid/portofolio_masjid/masjid_ujung_genteng/MasjidUjungGenteng';
import AppDetailPortofolioHnsPIK2House from './components/pages/components/portofolio/detail_portofolio/detail_portofolio_hns_house/AppDetailPortofolioHnsPIK2House';

function App() {

  return (
    <div className="app-section">
      <Router>
        
        {/** 
        <Helmet>
        </Helmet>
        */}

        <DefaultScrollToTop />
        <Routes>
          <Route>
            <Route path="/" element={<Home />} />

            {/** ROUTE IMAGE FILTER GALLERY */}
            {/** <Route path="/image-archive" element={<Tabs/>} /> */}

            {/** ROUTE OUR ALL PROJECT */}

            {/** ROUTE OUR MY PROJECT */}
            <Route path="/interior-project" element={<ProjectInterior />} />
            <Route path="/commercial-project:maintenance" element={<ProjectKomersial />} />
            <Route path="/mosque-project" element={<ProjectMasjid />} />
            <Route path="/masterplan-project" element={<ProjectMasterplan />} />
            <Route path="/house-project" element={<ProjectRumah />} />

            {/** ROUTE DESIGN MASJID POP UP MODAL DETAIL PAGES*/}
            <Route path="/design-masjid-padang" element={<MasjidPadang/>} />
            <Route path="/design-al-wushul" element={<MasjidAlWushul/>} />
            <Route path="/design-masjid-cempaka" element={<MasjidCempaka/>} />
            <Route path="/design-masjid-al-azhar" element={<MasjidAlAzhar/>} />
            <Route path="/design-masjid-aceh" element={<MasjidAceh/>} />
            <Route path="/design-masjid-asy-syifa" element={<MasjidAsySyifa/>} />
            <Route path="/design-masjid-baitul-muslimin" element={<MasjidBaitulMuslimin/>} />
            <Route path="/design-masjid-bumi-kiara-payung" element={<MasjidBumiKiaraPayung/>} />
            <Route path="/design-masjid-cordova1" element={<MasjidCordova1/>} />
            <Route path="/design-masjid-cordova2" element={<MasjidCordova2/>} />
            <Route path="/design-masjid-darul-muttaqin" element={<MasjidDarulMuttaqin/>} />
            <Route path="/design-masjid-kaf-pwk" element={<MasjidKafPwk/>} />
            <Route path="/design-masjid-kalang1" element={<MasjidKalang/>} />
            <Route path="/design-masjid-kalang2" element={<MasjidKalangsari/>} />
            <Route path="/design-masjid-kertayasa" element={<MasjidKertayasa/>} />
            <Route path="/design-masjid-lela" element={<MasjidLela/>} />
            <Route path="/design-masjid-miftahurrahman" element={<MasjidMiftahurrahmah/>} />
            <Route path="/design-masjid-muhajirin" element={<MasjidMuhajirin/>} />
            <Route path="/design-masjid-nurulislam" element={<MasjidNurulislam/>} />
            <Route path="/design-masjid-pacet" element={<MasjidPacet/>} />
            <Route path="/design-masjid-parigi" element={<MasjidParigi/>} />
            <Route path="/design-masjid-pondok-pinang" element={<MasjidPondokPinang/>} />
            <Route path="/design-masjid-raya-bumi-orange" element={<MasjidRayaBumiOrange/>} />
            <Route path="/design-masjid-smp-mutiara" element={<MasjidSmpMutiara/>} />
            <Route path="/design-masjid-sragen" element={<MasjidSragen/>} />
            <Route path="/design-masjid-sukamanah" element={<MasjidSukamanah/>} />
            <Route path="/design-masjid-tumbuh" element={<MasjidTumbuh/>} />
            <Route path="/design-masjid-ujung-genteng" element={<MasjidUjungGenteng/>} />

            {/** ROUTE DETAILS PORTOFOLIO */}
            <Route path="/detail-project/portofolio-grand-wisata-house" element={<AppDetailPortofolio01 />} />
            <Route path="/detail-project/portofolio-cifor-house" element={<AppDetailPortofolioCiforHouse />} />
            <Route path="/detail-project/portofolio-fawn-house" element={<AppDetailPortofolioFawnHouse />} />
            <Route path="/detail-project/portofolio-awiligar-house" element={<AppDetailPortofolioAwiligarHouse />} />
            <Route path="/detail-project/portofolio-ytm-house" element={<AppDetailPortofolioYtmHouse />} />
            <Route path="/detail-project/portofolio-ydi-house" element={<AppDetailPortofolioYdiHouse />} />
            <Route path="/detail-project/portofolio-dod-house" element={<AppDetailPortofolioDodHouse />} />
            <Route path="/detail-project/portofolio-maf-sd-house" element={<AppDetailPortofolioMafSd />} />
            <Route path="/detail-project/portofolio-villa-house" element={<AppDetailPortofolioVillaHouse />} />
            <Route path="/detail-project/portofolio-hnsPIK2-house" element={<AppDetailPortofolioHnsPIK2House />} />

            <Route path="/detail-project/portofolio-darussalam-mosque" element={<AppDetailPortofolioDarussalamMosque />} />

            <Route path='/our-location' element={<MapsLocation />} />
            <Route path='/contact-us' element={<ContactUs />} />

            <Route path='/about-us' element={<About />} />
            <Route path='/about-rekanata' element={<AppRekanata />} />

            <Route path='/our-service-details:id/design-and-build' element={<OurServiceViewAll />} />

            {/** ROUTE MY SERVICE */}
            <Route path='/service' element={<Service />} />

            {/** ROUTE OUR SERVICE DETAILS */}
            <Route path='/service-detail:id/design-and-build' element={<DesignAndBuild />} />
            <Route path='/service-detail:id/interior-design' element={<InteriorDesign />} />
            <Route path='/service-detail:id/masjid' element={<Masjid />} />
            <Route path='/service-detail:id/masterplan' element={<Masterplan />} />
            <Route path='/service-detail:id/renovasi' element={<Renovasi />} />

            <Route path='/free-design' element={<FreeDesign />} />

            <Route path='/blog' element={<AppBlog />} />

            <Route path='/penggunaan-bahan-material' element={<ExampleBlog1 />} />

            {/* error page section */}
            <Route path="*" element={<ErrorPage />} />
          </Route>
        </Routes>
        {/*<SmoothScrollToTop/>*/}
      </Router>
    </div>
  );
}

export default App;